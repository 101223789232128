import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './context/authContext';
import axios from 'axios';
import { TempProvider } from './context/tempContext';
import { ThemeProvider } from '@emotion/react';
import { ApiProvider } from './context/apiContext';
// import { Provider } from 'react-redux';
// import { store } from './redux/store';
import { theme } from './theme';
import { responsiveFontTheme  } from './theme';

// // replace console.* for disable log on production
// if (process.env.REACT_APP_CURRENT_ENV === 'LIVE') {
  // console.log = () => {}
  // console.error = () => {}
  // console.debug = () => {}
// }

// Toggle between responsive and non-responsive font theme
const selectedTheme = theme;

axios.defaults.withCredentials = true; // even for get requests if
// demand session authentication
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken'

// ALL REACT PAGES (FOR TESTING)
const rootElement = document.getElementById('root');
if (rootElement != null) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    // <StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            {/* <Provider store={store}> */}
              <ApiProvider>
                <TempProvider>
                  <ThemeProvider theme={selectedTheme}>
                    <App />
                  </ThemeProvider>
                </TempProvider>
              </ApiProvider>
            {/* </Provider> */}
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    // </StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

