import { useRoutes } from 'react-router-dom';
import './App.css';
import { userRoutes } from './routes';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import { Dialog, Grid, IconButton, responsiveFontSizes } from '@mui/material';
import { useState } from 'react';
import { ArrowSVG, MoreMenuSVG } from './assets/icons';
import { LogoHorizontal } from './assets/logos';


function App() {
  const userContent = useRoutes(userRoutes);
  const responsiveTheme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={responsiveTheme}>
      {/* <Header /> */}
      {/* <Side> */}
        {userContent}
      {/* </Side> */}
      {/* <FeedbackPopout visible /> */}
    </ThemeProvider>
  );
}

export default App;

const Header = () => {
  return (
    <div
      style={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'0 24px',
        height:'60px',
        boxShadow:'0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)'
      }}
    >
      <LogoHorizontal height={23} />
    </div>
  )
}

// const Side = ({ children }) => {
//   const [sidemenu, setSidemenu] = useState(false);

//   return (
//     <>
//       {
//         sidemenu
//           ?
//             <Grid
//               style={{
//                 display:'flex',
//                 position:'relative',
//                 alignItems:'flex-start',
//                 overflow:'clip'
//               }}
//             >
//               <Grid
//                 style={{
//                   position:'sticky',
//                   top:0,
//                   left:0,
//                   height:'100vh',
//                   background:'#eee',
//                   display:'flex',
//                   width:'auto',
//                   flexDirection:'column',
//                   justifyContent:'flex-start',
//                   zIndex:600,
//                 }}
//               >
//                 <SideMenu />
//               </Grid>
//               <Grid
//                 style={{
//                   flex:1
//                 }}
//               >
//                 {children}
//               </Grid>
//             </Grid>
//           :
//             children
//         }
//         <IconButton
//           onClick={() => setSidemenu(prev => !prev)}
//           style={{
//             position:'fixed',
//             bottom:20,
//             right:60,
//             zIndex:10000
//           }}
//         >
//           <ArrowSVG
//             width={25}
//             style={{
//               transform: !sidemenu ? 'rotate(0deg)' : 'rotate(180deg)',
//               transition:'.3s'
//             }}
//           />
//         </IconButton>

//     </>
//   )
// }