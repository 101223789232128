import { Suspense, lazy } from 'react';
import LoadingScreen from './components/loading/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const LandingHomePage = Loadable(lazy(() => import('./pages/LandingHomePage')));
const LandingContactPage = Loadable(lazy(() => import('./pages/LandingContactPage')));
const LandingPricingPage = Loadable(lazy(() => import('./pages/LandingPricingPage')));
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage')));
const SignupPage = Loadable(lazy(() => import('./pages/SignupPage')));
const EmailTemplatesPage = Loadable(lazy(() => import('./pages/EmailTemplatesPage')));
const EmailTemplatePage = Loadable(lazy(() => import('./pages/EmailTemplatePage')));
const TermsPage = Loadable(lazy(() => import('./pages/TermsPage')));
const TermsServicePage = Loadable(lazy(() => import('./pages/TermsServicePage')));
const TermsPrivacyPage = Loadable(lazy(() => import('./pages/TermsPrivacyPage')));
const TermsCookiesPage = Loadable(lazy(() => import('./pages/TermsCookiesPage')));

export const userRoutes = [
  { path: '/', element: <LandingHomePage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'signup', element: <SignupPage /> },
  { path: 'contact', element: <LandingContactPage /> },
  { path: 'pricing', element: <LandingPricingPage /> },
  { path: 'templates', element: <EmailTemplatesPage /> },
  { path: 'templates/new', element: <EmailTemplatePage /> },
  { path: 'templates/:reactTemplateId', element: <EmailTemplatePage /> },
  { path: 'terms', element: <TermsPage /> },
  { path: 'terms/privacy', element: <TermsPrivacyPage /> },
  { path: 'terms/service', element: <TermsServicePage /> },
  { path: 'terms/cookies', element: <TermsCookiesPage /> },
  {
    // path: '/*', element: <NotFoundPage />
  }
];